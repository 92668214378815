import { type FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { Button, LinkButton as _LinkButton } from '@dutchie-pos/components';

import DutchieLogo from 'src/assets/dutchie-logo.svg?react';
import UserIcon from 'src/assets/icon-account.svg?react';
import PasswordIcon from 'src/assets/icon-password.svg?react';
import { DutchieInput } from 'src/components/dutchie-input';
// import { Link as _Link } from 'src/components/link';
import { colors } from 'src/css/theme';
import { type LoginResponseRootObject, useLoginMutation } from 'src/queries/curbside/login';
import { useUpdateForgotPasswordMutation } from 'src/queries/posv3/forgot-password';
import { useSSOLoginEnabledQuery } from 'src/queries/saml/sso-login-enabled';
import { useSSOLoginRedirectMutation } from 'src/queries/saml/sso-login-redirect';
import { errorNotification, successNotification } from 'src/store/actions/notifications-actions';
import { setUser } from 'src/store/slices/user';
import { useAppDispatch, useAppSelector } from 'src/utils';

import { Alerts } from '../components/alerts';

type LoginProps = {
  isSSO?: boolean;
};

export const Login: FC<LoginProps> = ({ isSSO = false }) => {
  const navigate = useNavigate();
  const session = useAppSelector((state) => state.user.session);

  // Redirect to user page if already logged in
  useEffect(() => {
    if (session) {
      navigate('/locations');
    }
  }, [navigate, session]);

  // Global state

  // Local state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Hooks
  const dispatch = useAppDispatch();
  const { data: isSSOLoginEnabled } = useSSOLoginEnabledQuery();
  const { mutate: SSOLoginRedirectMutator } = useSSOLoginRedirectMutation();
  const { mutate: updateForgotPasswordMutator } = useUpdateForgotPasswordMutation();
  const { mutate: loginMutator, isPending: isLoading } = useLoginMutation();

  function handleInvalidLogin(data?: LoginResponseRootObject) {
    if (!data?.Result && data?.Data) {
      if (
        (!data.Data.LoginFailureCount && !data.Data.MaxLoginFailureCount) ||
        data.Data.LoginFailureCount < data.Data.MaxLoginFailureCount
      ) {
        dispatch(errorNotification('Invalid login credentials'));
      } else {
        dispatch(
          errorNotification(
            `Your account has been blocked after ${data.Data.MaxLoginFailureCount} login failures. Please wait ${
              data.Data.MaxRetryMinutes - data.Data.RetryMinutes
            } minutes and try again.`,
          ),
        );
      }
    } else {
      dispatch(errorNotification('Something went wrong'));
    }
  }

  const login = () => {
    if (!username || !password) {
      setError('Please enter a username/password');
      return;
    }
    setError('');
    loginMutator(
      { username, password },
      {
        onSuccess: (data) => {
          if (!data?.Result) {
            handleInvalidLogin(data);
          } else {
            dispatch(setUser(data?.Data));
          }
        },
        onError: () => handleInvalidLogin(),
      },
    );
  };

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const forgotPassword = () => {
    if (!username) {
      setError('Please enter a username');
      return;
    }

    setError('');
    updateForgotPasswordMutator(
      { username },
      {
        onSuccess: () => {
          void dispatch(successNotification('A password reset link has been sent to your email'));
        },
        onError: () => {
          void dispatch(errorNotification('An error occurred while sending the password reset link'));
        },
      },
    );
  };

  return (
    <>
      <Alerts />
      <DarkBackground>
        <Container>
          <div>
            <LargeTitle>Welcome,</LargeTitle>
            <Title>Sign in to Mobile Checkout!</Title>
          </div>
          <FormContainer>
            <ErrorMessage data-testid='login-error'>{error}</ErrorMessage>
            <InputContainer>
              <DutchieInput
                placeholder='Username'
                startAdornment={<UserIcon />}
                value={username}
                withDarkBackground
                onChange={(e) => setUsername(e?.target?.value || '')}
                onFocus={() => setError('')}
                onKeyPress={handleKeyPress}
              />
            </InputContainer>
            {!isSSO && (
              <InputContainer>
                <DutchieInput
                  placeholder='Password'
                  startAdornment={<PasswordIcon />}
                  type='password'
                  value={password}
                  withDarkBackground
                  onChange={(e) => setPassword(e?.target?.value || '')}
                  onFocus={() => setError('')}
                  onKeyPress={handleKeyPress}
                />
              </InputContainer>
            )}
            {!isSSO && <LinkButton label='Forgot Password?' variant='white' onClick={forgotPassword} />}
            <Buttons>
              {!isSSO && (
                <Button
                  disabled={username === '' || password === ''}
                  fullWidth
                  label='Login'
                  loading={isLoading}
                  onClick={login}
                />
              )}
              {(isSSO || isSSOLoginEnabled) && (
                <Button
                  disabled={username === ''}
                  onClick={() => SSOLoginRedirectMutator({ username })}
                  label='Login with SSO'
                  // loading={isLoading}
                  fullWidth
                />
              )}
            </Buttons>
          </FormContainer>
          <Logo data-testid='login-dutchie-logo' />
        </Container>
        {/* {isLoading && <LoadingScreen />} */}
        {/* <ScreenLoader speed='fast' top={headerHeight} visible={isLoading} /> */}
      </DarkBackground>
    </>
  );
};

const DarkBackground = styled.div`
  flex-grow: 1;
  background: var(--color-opal-90);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 2rem 3rem;
  width: 100%;
  max-width: 500px;
`;

const Title = styled.h1`
  color: white;
  font-family: 'Matter';
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const LargeTitle = styled(Title)`
  font-size: 1.875rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  color: var(--color-gray-60);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
`;

const LinkButton = styled(_LinkButton)`
  align-self: flex-end;
  margin-bottom: 2rem;
`;

const Buttons = styled.div`
  display: grid;
  gap: 0.75rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`;

const Logo = styled(DutchieLogo)`
  align-self: center;
`;
