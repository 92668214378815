import { type FC } from 'react';

import { styled } from 'styled-components';

import { colors } from 'src/css/theme';

export const DebitWarning: FC = () => (
  <DebitWarningContainer>
    {/* <InfoIcon /> */}
    <DebitText>
      <DebitWeightedText>Regala not available.</DebitWeightedText>
      &nbsp;Regala transactions are not supported on orders totaling $2000 or more.
    </DebitText>
  </DebitWarningContainer>
);

const DebitWarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: var(--color-blue-10);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 1rem;
  width: 100%;
`;

const DebitText = styled.span`
  font-weight: 400;
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: var(--color-blue-80);
  padding-left: 8px;
`;

const DebitWeightedText = styled.span`
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: var(--color-blue-80);
`;
