import { type FC } from 'react';

import { styled } from 'styled-components';

import { ErrorMessageIcon } from 'src/assets/error-message';
import { SuccessMessageIcon } from 'src/assets/success-message';
import { WarnMessageIcon } from 'src/assets/warning-message';
import { removeNotification } from 'src/store/actions/notifications-actions';
import { NotificationType } from 'src/store/reducers/notifications-reducer';
import { useAppDispatch, useAppSelector } from 'src/utils';

export const NotificationsContainerID = 'system_notifications_container';

export const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications);

  const getNotificationIcon = (notificationType: NotificationType) => {
    switch (notificationType) {
      case NotificationType.Success:
        return (
          <NotificationIcon data-testid='notifications_successicon_success'>
            <SuccessMessageIcon />
          </NotificationIcon>
        );
      case NotificationType.Warning:
        return (
          <NotificationIcon data-testid='notifications_warningicon_warning'>
            <WarnMessageIcon />
          </NotificationIcon>
        );
      case NotificationType.Error:
        return (
          <NotificationIcon data-testid='notifications_erroricon_error'>
            <ErrorMessageIcon />
          </NotificationIcon>
        );
      default:
        return null;
    }
  };

  const handleNotificationClick = (id: string) => dispatch(removeNotification(id));

  return (
    <NotificationContainer data-testid='notifications_div_notecontainer' id={NotificationsContainerID}>
      {notifications.map((notification) => (
        <Notification
          data-dd-action-name={`"${notification.message}" notification (dismissed)`}
          data-testid='notification'
          key={notification.id}
          onClick={() => handleNotificationClick(notification.id)}
        >
          {getNotificationIcon(notification.type)}
          <NotificationMessage data-testid='notifications_div_notification'>
            <NotificationLabel data-testid='notifications_div_notification-type'>{notification.type}</NotificationLabel>
            <NotificationMessageContent>{notification.message}</NotificationMessageContent>
          </NotificationMessage>
        </Notification>
      ))}
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  position: absolute;
  top: 75px;
  right: 20px;
  z-index: var(--z-index-notifications);
  pointer-events: none;
`;

const Notification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--color-grayscale-black);
  border-radius: 0.5rem;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  float: right;
  clear: both;
  pointer-events: all;
  cursor: pointer;
`;

const NotificationMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: space-between;
  height: min-content;
  min-width: 260px;
  width: min-content;
  font-size: 0.875rem;
  line-height: 1rem;
`;

const NotificationLabel = styled.div`
  color: var(--color-grayscale-white);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const NotificationMessageContent = styled.div`
  color: var(--color-gray-40);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: pre-wrap;
`;

const NotificationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 2rem;
  flex-shrink: 0;

  & > svg {
    width: 2rem;
    height: 2rem;
  }
`;
