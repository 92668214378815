import { styled } from 'styled-components';

import { Header } from './header';

export const Layout = ({ children }) => (
  <>
    <Header />
    <Container>{children}</Container>
  </>
);

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
