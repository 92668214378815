export const colors = {
  black: '#363648', // var()
  borderGrey: '#C4D2D9', // var()
  grey: '#797A7A', // var()
  lightGrey: '#F2F2F2', // var()
  primary: '#1A3952', // var()
  realBlack: '#000000', // var()
  red: '#FF0000', // var()
  success: '#00d592', // var()
  blue: '#1DA1F2', // var()
  yellow: '#FFB300', // var()
  dutchie: {
    darkGrey: '#464F53', // var()
    lightGrey: '#F2F3F4', // var()
    shadowGrey: '#E5E5E5', // var()
    grey: '#788287', // var()
    almostBlack: '#171A1C', // var()
    gunmetal: '#2E3538', // var()
  },
} as const;
