import { type FC } from 'react';

import { styled } from 'styled-components';

import ErrorSvg from 'src/assets/notification-error.svg?react';
import SuccessSvg from 'src/assets/notification-success.svg?react';
import { colors } from 'src/css/theme';
import { removeNotification } from 'src/store/actions/notifications-actions';
import { NotificationType } from 'src/store/reducers/notifications-reducer';
import type { Notification } from 'src/store/reducers/notifications-reducer';
import { useAppSelector, useAppDispatch } from 'src/utils/hooks';

export const Alerts: FC = () => {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector((state) => state.notifications);

  const handleNotificationClick = (id: string) => dispatch(removeNotification(id));

  return (
    <Container>
      {notifications.map((notification: Notification) => (
        <Alert
          isError={notification.type === NotificationType.Error}
          key={notification.id}
          onClick={() => handleNotificationClick(notification.id)}
        >
          <AlertIcon isError={notification.type === NotificationType.Error}>
            {notification.type === NotificationType.Error ? <ErrorIcon /> : <SuccessIcon />}
          </AlertIcon>
          <AlertMessage>{notification.message}</AlertMessage>
        </Alert>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 5.75rem;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding: 0 1rem;
  z-index: var(--z-index-notifications);
`;

const Alert = styled.div<{ isError: boolean }>`
  background: ${colors.dutchie.almostBlack};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const AlertIcon = styled.div<{ isError: boolean }>`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isError }) => (isError ? 'var(--color-red-50)' : '#065F46')};
  flex-shrink: 0;
`;

const SuccessIcon = styled(SuccessSvg)`
  width: 100%;
  height: 100%;
`;

const ErrorIcon = styled(ErrorSvg)`
  width: 100%;
  height: 100%;
  margin: 0.4rem;
  & path {
    fill: black;
    stroke: black;
  }
`;

const AlertMessage = styled.div`
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5%;
`;
