import styled from '@emotion/styled';
import { IconButton as MUIIconButton } from '@mui/material';

import type { IconButtonProps } from '@mui/material';

export type StyledIconButtonProps = IconButtonProps;
export const StyledIconButton = styled(MUIIconButton)`
  :hover {
    background: none;
  }

  :focus {
    outline: 0;
  }
`;
