import { styled } from 'styled-components';

import { DutchieConfirmationPopup } from 'src/components/popups/confirmation-popup';

type ConfirmRemoveItemPopupProps = {
  hide: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmRemoveItemPopup = ({ hide, onConfirm, onCancel }: ConfirmRemoveItemPopupProps) => (
  <DutchieConfirmationPopup
    cancel={{ onClick: onCancel, text: 'Cancel' }}
    confirm={{
      onClick: () => {
        onConfirm();
        hide();
      },
      text: 'Confirm',
    }}
    hide={hide}
    isVisible
    title='Are you sure?'
  >
    <Message>Do you want to remove this item?</Message>
  </DutchieConfirmationPopup>
);

const Message = styled.div`
  padding: 0px 20px;
  text-align: center;
`;
