import { type ReactNode } from 'react';

// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
import createCache from '@emotion/cache';
import { ThemeProvider as EmotionThemeProvider, Global, CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { defaultBreakpoints } from '../breakpoints';
import { tokens } from '../tokens';
import { universals } from '../universals';
import '../../css/main.scss';

import { defaultTheme } from './theme-definition';

const myCache = createCache({ key: 'css' });
myCache.compat = true;

/**
 * These providers _should_ only apply to components within this library that are built using @emotion, not styled-components.
 */
export function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <CacheProvider value={myCache}>
      <EmotionThemeProvider theme={defaultBreakpoints}>
        <MuiThemeProvider theme={defaultTheme}>
          <EmotionThemeProvider theme={defaultTheme}>
            <Global styles={tokens} />
            <Global styles={universals} />
            {children}
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </EmotionThemeProvider>
    </CacheProvider>
  );
}
