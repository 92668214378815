import { styled } from 'styled-components';

import Chevron from 'src/assets/chevron-left.svg?react';
import { colors } from 'src/css/theme';

type ArrowProps = {
  color?: string;
  disabled?: boolean;
  side: 'down' | 'left' | 'right' | 'up';
};

export const Arrow = styled(Chevron)<ArrowProps>`
  cursor: pointer;
  width: 1rem;
  height: 1rem;

  ${({ color }) => `color: ${color ?? colors.dutchie.almostBlack};`}

  ${({ side }) =>
    side === 'right'
      ? `transform: rotate(180deg);`
      : side === 'up'
        ? `transform: rotate(90deg);`
        : side === 'down'
          ? `transform: rotate(-90deg);`
          : ``}

    ${({ disabled }) =>
    disabled &&
    `
        cursor: normal;
        opacity: 0.25;
    `}
`;
