import { type Dispatch, type SetStateAction, createContext, useContext, useEffect, useState } from 'react';

import { type AppReady, MAX_3RD_PARTY_WAIT_TIME } from '..';

type PendoContextType = {
  pendo?: any;
  track?: (eventName: string, eventData: Record<string, unknown>, retryCount?: number) => void;
};

export const PendoContext = createContext<PendoContextType>({});

export function usePendo() {
  const pendo = useContext(PendoContext);

  // TODO: this track function is needed in the app
  // const analyticsWithDeferredPendoTrack: Analytics = {
  //   dangerousAllowMutability: true,
  //   key: 'analytics',
  //   track: (eventName, eventData, retryCount = 0) => {
  //     try {
  //       const appVersionEl = document.querySelector('meta[name="version"]') as HTMLMetaElement;
  //       const appVersion = appVersionEl?.content ?? 'unknown';
  //       const baseEventData = {
  //         host: window.location.hostname,
  //         appVersion,
  //         userFullName: user?.FullName,
  //         username: user?.UserName,
  //         userID: user?.Id,
  //         locId: user?.LocId,
  //         lspId: user?.LspId,
  //       };

  //       if (window.pendo?.isReady?.()) {
  //         window.pendo.track(eventName, { ...baseEventData, ...eventData });
  //       } else if (retryCount < 3) {
  //         setTimeout(() => analyticsWithDeferredPendoTrack.track(eventName, eventData, retryCount + 1), 500);
  //       }
  //     } catch (error) {
  //       console.error('Error tracking event', error);
  //     }
  //   },
  // };

  return pendo;
}

export async function initPendo(apiKey: string) {
  // eslint-disable-next-line no-return-await
  return await new Promise<void>((resolve) => {
    try {
      if (import.meta.env.PROD) {
        /* eslint-disable */
        /* @ts-ignore */
        (function (key) {
          (function (p, e, n, d, o) {
            // @ts-ignore
            let v, w, x, y, z;
            o = p[d] = p[d] || {};
            // @ts-ignore
            o._q = o._q || [];
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
            for (w = 0, x = v.length; w < x; ++w) {
              (function (m) {
                // @ts-ignore
                o[m] =
                  // @ts-ignore
                  o[m] ||
                  function () {
                    // @ts-ignore
                    o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                  };
              })(v[w]);
            }
            y = e.createElement(n);
            y.async = !0;
            y.src = `https://cdn.pendo.io/agent/static/${key}/pendo.js`;
            y.addEventListener('load', () => {
              resolve();
            });
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
          })(window, document, 'script', 'pendo');
        })(apiKey);
        /* eslint-enable */
      } else if (import.meta.env.DEV) {
        resolve();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize Pendo', error);

      // We will still load the page if Pendo fails to initialize
      resolve();
    }
  });
}

export type PendoProviderProps = {
  apiKey: string;
  children: React.ReactNode;
  ready: AppReady;
  setReady: Dispatch<SetStateAction<AppReady>>;
};

export function PendoProvider({ children, ready, setReady, apiKey }: PendoProviderProps) {
  const [pendoReady, setPendoReady] = useState(false);
  const [pendoForceReady, setPendoForceReady] = useState(false);
  // TODO: something like this..
  // const { data: user } = useUser();
  // const { data: location } = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPendoForceReady(true);
    }, MAX_3RD_PARTY_WAIT_TIME);

    async function init() {
      await initPendo(apiKey);
      setPendoReady(true);
    }
    void init();

    return () => clearTimeout(timeout);
  }, [apiKey]);

  useEffect(() => {
    // Check that the pendo ready state is not already set so the screen doesn't blip after the timeout
    if (!ready.pendo && (pendoReady || pendoForceReady)) {
      // eslint-disable-next-line no-console
      // TODO: init the visitor, then set ready
      // window?.pendo.initialize({
      //   visitor: {
      //     id: user.userId,
      //     email: user.UserName,
      //     full_name: user.FullName,
      //     LocId: user.LocId,
      //     LspId: user.LspId,
      //     LocName: location.location_name,
      //     Deployment: user.Region,
      //     location_id_and_region: `${user.LocId}${user.Region}`,
      //     State: location.State,
      //   },
      //   account: {
      //     id: user.LocId,
      //   },
      // });
      setReady((prev) => ({
        ...prev,
        pendo: true,
      }));
    }
  }, [ready.pendo, pendoReady, pendoForceReady, setReady]);

  if (!pendoReady && !pendoForceReady) {
    return null;
  }

  return <PendoContext.Provider value={window?.pendo}>{children}</PendoContext.Provider>;
}
