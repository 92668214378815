import { styled } from 'styled-components';

import { Loader } from '@dutchie-pos/components';

import { BarcodeReader } from 'src/app/components/barcode-reader';
import { QRCodeReader } from 'src/app/components/barcode-reader/barcode-reader';
import { Button } from 'src/components/button';
import { colors } from 'src/css/theme';

type ScanBarcodeDrawerProps = {
  isLoading?: boolean;
  onClose: () => void;
  onScan: (scannedString: string) => Promise<void>;
  qrCode?: boolean;
};

export const ScanBarcodeDrawer = ({ onClose, qrCode, onScan, isLoading }: ScanBarcodeDrawerProps): JSX.Element => (
  <Container>
    <TopSection>
      <Title>
        Scan {qrCode ? 'QR Code' : 'Barcode'}
        {isLoading && <Loader size='sm' variant='blue' />}
      </Title>

      <Button
        automationId='scan-barcode-drawer-close-button'
        ddActionName='Close (scan barcode drawer)'
        tertiary
        onClick={onClose}
      >
        Close
      </Button>
    </TopSection>
    <Separator />
    <MainContent>
      <BarcodeReaderContainer>
        {!qrCode && <BarcodeReader onScan={onScan} />}
        {qrCode && <QRCodeReader onScan={onScan} />}
      </BarcodeReaderContainer>
    </MainContent>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 2.2rem 1.125rem;
  @media (max-width: 1280px) and (max-height: 720px), (max-width: 1195px) {
    padding: 1.5rem 2rem 1.125rem 1.5rem;
  }
`;

const Title = styled.h1`
  font-weight: bolder;
  font-size: 1.6rem;
  display: flex;
  gap: 1rem;
`;

const Separator = styled.hr`
  margin: 0;
  background-color: ${colors.dutchie.shadowGrey};
  height: 1px;
`;

const MainContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const BarcodeReaderContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;
