import { type FC } from 'react';

import { styled } from 'styled-components';

import CheckboxMixed from 'src/assets/checkbox-mixed.svg?react';
import CheckboxOff from 'src/assets/checkbox-off.svg?react';
import CheckboxOn from 'src/assets/checkbox-on.svg?react';

import { DutchieLabel } from './label';

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const commonCheckboxStyles = {
  width: '20px',
  height: '20px',
};

const StyledCheckboxMixed = styled(CheckboxMixed)`
  ${commonCheckboxStyles}
`;

const StyledCheckboxOn = styled(CheckboxOn)`
  ${commonCheckboxStyles}
`;

const StyledCheckboxOff = styled(CheckboxOff)`
  ${commonCheckboxStyles}
`;

export type CheckboxState = 'mixed' | 'off' | 'on';

type CheckboxProps = {
  className?: string;
  label?: string;
  leadingLabel?: boolean;
  onClick?: () => void;
  state: CheckboxState;
  ddActionName?: string;
};

const checkboxForState = (state: CheckboxState) => {
  switch (state) {
    case 'mixed':
      return <StyledCheckboxMixed data-testid='checkbox-mixed_svg' />;
    case 'on':
      return <StyledCheckboxOn data-testid='checkbox-on_svg' />;
    default:
      return <StyledCheckboxOff data-testid='checkbox-off_svg' />;
  }
};

const StyledLabel = styled(DutchieLabel)`
  margin-bottom: 0;
`;

const LabelWrapper = styled.div<{ leading?: boolean; trailing?: boolean }>`
  margin-right: ${({ leading }) => (leading ? '10px' : 'none')};
  margin-left: ${({ leading }) => (leading ? 'none' : '10px')};
`;

const CheckboxLabel = ({ leading, children }) => (
  <LabelWrapper leading={leading}>
    <StyledLabel>{children}</StyledLabel>
  </LabelWrapper>
);

export const DutchieCheckbox: FC<CheckboxProps> = ({ state, className, onClick, label, leadingLabel, ddActionName }) => (
  <CheckboxWrapper className={className} onClick={onClick} data-dd-action-name={ddActionName}>
    {label && leadingLabel && <CheckboxLabel leading={leadingLabel}>{label}</CheckboxLabel>}
    {checkboxForState(state)}
    {label && !leadingLabel && <CheckboxLabel leading={leadingLabel}>{label}</CheckboxLabel>}
  </CheckboxWrapper>
);
