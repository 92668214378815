import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { ScreenLoader } from '@dutchie-pos/components';

import { useRoutes } from 'src/queries/curbside/get-my-routes';
import type { DeliveryRouteType } from 'src/queries/curbside/types';
import { setSelectedRouteId } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';

import { headerHeight } from '../components/header';
import { SelectRoute } from '../components/select-route';

export const Delivery = () => {
  // Global state
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading: isLoadingRoutes } = useRoutes(true);

  // Computed
  const routes = data ?? [];

  if (!selectedRegister) {
    navigate('/locations');
  }

  return (
    <Container>
      <SelectRoute
        list={routes}
        onSelection={(route: DeliveryRouteType) => {
          dispatch(setSelectedRouteId(route.DeliveryRouteId));
          navigate(`/route/delivery/${route.DeliveryRouteId}`);
        }}
      />
      <ScreenLoader speed='fast' top={headerHeight} visible={isLoadingRoutes} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;
