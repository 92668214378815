import React, { type FC, useCallback, useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { Button } from '@dutchie-pos/components';

import { PaymentType } from 'src/app/types/payments';
import DutchiePay from 'src/assets/dutchie-pay-logo.svg?react';
import ErrorIcon from 'src/assets/icon-error.svg?react';
import InfoIcon from 'src/assets/info.svg?react';
import { DutchieInput } from 'src/components/dutchie-input';
import { type CheckoutRequest, useCheckout } from 'src/queries/curbside/checkout';
import { useCart } from 'src/queries/curbside/get-cart';
import { useDeliveryInfo } from 'src/queries/curbside/get-delivery-info';
import { GetOrdersForRouteKey } from 'src/queries/curbside/get-orders-for-route';
import { GuestListKey } from 'src/queries/curbside/guest-list';
import { useLocation } from 'src/queries/curbside/location-details';
import { usePay } from 'src/queries/curbside/pay';
import { FeePaymentMethod } from 'src/queries/curbside/types';
import { usePollPaymentQuery } from 'src/queries/payment/poll-payment';
import { usePrintReceipt } from 'src/queries/v2/print-jobs/print-receipt';
import { usePrintRegalaReceipt } from 'src/queries/v2/print-jobs/print-regala-receipt';
import {
  setActiveCart,
  setChangeAmountDue,
  setDutchiePayErrorMessage,
  setIsCheckoutComplete,
  setIsDutchiePayError,
  setIsRethinkError,
  setRethinkErrorMessage,
  setTotalAmountPaid,
} from 'src/store/actions/MobileCheckoutActions';
import { errorNotification, successNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch, useAppSelector } from 'src/utils';
import { dutchiePayReasonCodes, getDutchiePayErrorMessage } from 'src/utils/helpers/payments/dutchie-pay-errors';
import { getFeeNameFromProcessor } from 'src/utils/helpers/payments/get-fee-name-from-processor';
import { useHasPreauth } from 'src/utils/hooks/use-has-preauth';
import { useIsDutchiePay } from 'src/utils/hooks/use-is-dutchie-pay';
import { useIsRethink } from 'src/utils/hooks/use-is-rethink';
import { customEventKeys, logger } from 'src/utils/logger';

import { CanPayCheckout } from './canpay-checkout';
import { CartSummary } from './cart-order-summary';
import { CartOrderTotal } from './cart-order-total';
import { DebitWarning } from './debit-warning';

export type PaymentFormTotals = {
  changeDue: number;
  creditNonCashAdjustmentFees: number;
  discount: number;
  dutchiePayFees: number;
  feesDonations: number;
  nonCashAdjustmentFees: number;
  rounding: number;
  subtotal: number;
  tax: number;
  tip: number;
  total: number;
  totalPaid: number;
};

type PaymentFormProps = {
  onFailure: () => void;
  onPaymentSuccess: () => void;
  totals: PaymentFormTotals;
  validationNeeded?: boolean;
};

export const PaymentForm: FC<PaymentFormProps> = ({
  onFailure,
  onPaymentSuccess,
  totals,
  totals: { subtotal, tax, tip, total },
  validationNeeded,
}) => {
  // Global state
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister);
  const selectedReceiptPrinterId = useAppSelector((state) => state.user.selectedRegister?.ReceiptPrinterId);
  const changeAmountDue = useAppSelector((state) => state.mobileCheckout.changeAmountDue);
  const isDutchiePayError = useAppSelector((state) => state.mobileCheckout.isDutchiePayError);
  const dutchiePayErrorMessage = useAppSelector((state) => state.mobileCheckout.dutchiePayErrorMessage);
  const isRethinkError = useAppSelector((state) => state.mobileCheckout.isRethinkError);
  const rethinkErrorMessage = useAppSelector((state) => state.mobileCheckout.rethinkErrorMessage);
  const selectedRouteId = useAppSelector((state) => state.user.selectedRouteId);

  // Local state
  const [cash, setCash] = useState(0.0);
  const [giftcardPaid, setGiftcardPaid] = useState(0.0);
  const [debit, setDebit] = useState(0);
  const [addedSurcharge, setAddedSurcharge] = useState(false);
  const [preauthAmt, setPreauthAmt] = useState(0.0);
  const [showCanPayCheckout, setShowCanPayCheckout] = useState(false);
  const [isPaid, setIsPaid] = useState(!!activeCart?.Payment?.ElectronicAmount || !!debit);
  const [electronicPaymentType, setElectronicPaymentType] = useState<string>();
  const [electronicPaid, setElectronicPaid] = useState<string>('');
  const [canpayPaid, setCanpayPaid] = useState(() => {
    if (['canpay'].includes(activeCart?.Payment?.ElectronicType || '')) {
      return activeCart?.Payment?.ElectronicAmount ?? 0;
    }

    return 0;
  });

  // Refs
  const cashInputRef = useRef<HTMLInputElement | null>(null);
  const debitInputRef = useRef<HTMLInputElement | null>(null);
  const giftCardInputRef = useRef<HTMLInputElement | null>(null);
  const canPayInputRef = useRef<HTMLInputElement | null>(null);

  const { data: location } = useLocation();

  // Computed
  const integratedDebitProvider = location?.Integrations.IntegratedDebitProvider;
  const debitProcessor = location?.Integrations.DebitProcessor;
  const isAllowDutchiePayDeliveryFFEnabled = !!location?.Features.AllowDutchiePayDelivery;
  const isUseRethinkIntegrationEnabled = location?.Integrations.UseReThink;
  const isAcceptCanPayFFEnabled = !!location?.Features.AcceptCanPay;
  const isCurbsideCashlessFFEnabled = !!location?.Features.CurbsideCashless;
  const isCheckOutGiftCardFFEnabled = !!location?.Features.CheckOutGiftCard;
  const useUSAG = debitProcessor === 'usag';
  const paymentFeeName = getFeeNameFromProcessor(debitProcessor);
  // USAG transactions cannot be for over $200
  // SOP is to make the budtender split up into multiple transactions
  const debitDisabled = useUSAG && total >= 200;

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDutchiePay = useIsDutchiePay();
  const isRethink = useIsRethink();
  const hasPreauth = useHasPreauth();
  const { shipmentId } = useParams();
  const queryClient = useQueryClient();

  const {
    data: paymentSuccess,
    isFetching: isPollingPayment,
    refetch: pollPayment,
  } = usePollPaymentQuery({
    ElectronicPaymentType: electronicPaymentType ?? integratedDebitProvider ?? '',
    ShipmentId: parseInt(shipmentId ?? '0', 10),
  });
  const { isPending: isPrintingReceipt, mutate: printReceipt } = usePrintReceipt({
    Subtotal: subtotal,
    Total: total,
  });
  const { isPending: isRegalaPrintingReceipt, mutate: printRegalaReceipt } = usePrintRegalaReceipt({
    Subtotal: subtotal,
    Total: total,
  });

  async function handlePollPayment() {
    const { error } = await pollPayment();
    if (error) {
      dispatch(errorNotification(`Debit payment failed - Unable to retrieve payment status`));
    }
  }

  const { isPending: isPaying, mutate: pay } = usePay({
    ElectronicPaid: electronicPaid,
    ElectronicPaymentType: electronicPaymentType ?? integratedDebitProvider ?? '',
    onSuccess: (resp) => {
      if (!resp) {
        dispatch(errorNotification('Error processing payment'));
        return;
      }

      if (resp.IsPolling) {
        void handlePollPayment();
      } else {
        dispatch(setActiveCart({ ...activeCart, TipAmount: resp.TipAmount }));
        onPayDebitSuccess();
      }
    },
  });

  const {
    data: cartDetail,
    isFetching: isLoadingCartDetail,
    refetch: getCartDetailsAgain,
  } = useCart({
    FeePaymentMethodIds: debit > 0 ? [FeePaymentMethod.PinDebit] : [],
  });

  useEffect(() => {
    if (cartDetail) {
      // TODO: this shouldn't be necessary.. just use the hook. mind the tipAmount being set above though...
      // finish removing activeCustomer from the store first?
      dispatch(setActiveCart(cartDetail));

      setIsPaid(!!cartDetail?.Payment?.ElectronicAmount || !!debit);
      for (const payment of cartDetail.ExistingPayments) {
        if (payment.type === 2) {
          setDebit(payment.amount);
          setIsPaid(true);
        }
        // TODO: add credit here for rethink
        // else if(payment.type === 1) {
        //   setCredit(payment.amount);
        // }
      }
      if (!isDutchiePayError) {
        dispatch(
          setIsDutchiePayError((cartDetail?.ExistingPayments?.length ?? 0) > 0 || !isAllowDutchiePayDeliveryFFEnabled),
        );
      }

      if (!isRethinkError) {
        dispatch(setIsRethinkError((cartDetail?.ExistingPayments?.length ?? 0) > 0 || !isUseRethinkIntegrationEnabled));
      }
    }
  }, [cartDetail]);

  const { isLoading: isLoadingDeliveryInfo, refetch: getDeliveryInfoAgain } = useDeliveryInfo();

  const prepaymentType =
    preauthAmt > 0 ? (isDutchiePay ? PaymentType.Dutchiepay : isRethink ? PaymentType.Rethink : '') : '';

  const { isPending: isCheckingOut, mutateAsync: checkout } = useCheckout({
    onError: (ex: Error) => {
      dispatch(setIsCheckoutComplete(false));
      // TODO: we should really handle these errors differently
      if (ex.message.includes('Dutchie Pay')) {
        const reasonCode = parseReasonCode(ex.message);
        // If the error has a valid reason code, include it in the error message. Otherwise, just use the generic error message without the reason code.
        ex.message = `${getDutchiePayErrorMessage(reasonCode).pos} ${
          dutchiePayReasonCodes.includes(reasonCode) ? reasonCode : ''
        }`;
        dispatch(setIsDutchiePayError(true));
        dispatch(setDutchiePayErrorMessage(ex.message));
        void openCart();
      } else if (ex.message.match(/Re[Tt]hink/g)) {
        dispatch(setIsRethinkError(true));
        dispatch(setRethinkErrorMessage(ex.message));
        void openCart();
      } else {
        dispatch(errorNotification(ex.message || 'Checkout failed'));
      }
      // Finally, throw the error for caller to handle failed checkout
      onFailure();
    },
    onSuccess: () => {
      dispatch(setIsCheckoutComplete(true));

      void queryClient.invalidateQueries({ queryKey: [GetOrdersForRouteKey, selectedRouteId] });
      void queryClient.invalidateQueries({ queryKey: [GuestListKey, selectedRegister?.id] });

      if (selectedReceiptPrinterId) {
        printReceipt();
      }

      onPaymentSuccess();
    },
    Request: {
      CCPaid: prepaymentType === PaymentType.Rethink ? preauthAmt : null,
      DebitPaid: debit,
      DueCustomer: changeAmountDue,
      GiftPaid: giftcardPaid,
      OrderId: activeCart?.PreauthInfo?.OrderId ?? 0,
      Paidamt: cash,
      PaymentMethods: [],
      // The below properties added to implement Dutchie Pay
      // The order Id is required for finalization
      PreAuth: preauthAmt ?? 0,
      PrepaymentType: prepaymentType,
      SubTotal: subtotal,
      TaxAmt: tax,
      TotalCharges: total,
    } as CheckoutRequest,
  });

  async function openCart() {
    dispatch(setIsCheckoutComplete(false));
    await getCartDetailsAgain();
    await getDeliveryInfoAgain();
  }

  const onPayDebitSuccess = useCallback(() => {
    void dispatch(successNotification('Electronic payment success'));

    if (useUSAG && selectedReceiptPrinterId) {
      printRegalaReceipt();
    }

    const pinDebitFeeIds = (activeCart?.FeesByPaymentType ?? [])
      .filter((x) => x.FeePaymentMethod === FeePaymentMethod.PinDebit)
      .map((x) => x.FeeDonationId);

    if (Number(pinDebitFeeIds) > 0) {
      void openCart();
    }
  }, [dispatch, useUSAG, selectedReceiptPrinterId, activeCart, printRegalaReceipt, openCart]);

  const hasDebitPaymentFeeApplied = activeCart?.FeesDonations?.some(
    (x) => x.FeePaymentMethod === FeePaymentMethod.PinDebit,
  );

  const potentialNonCashAdjustments =
    (activeCart?.FeesByPaymentType ?? [])
      .filter((fd) => fd.FeePaymentMethod === FeePaymentMethod.PinDebit && !hasDebitPaymentFeeApplied)
      .map((fd) => fd.CashValue)
      .reduce((sum, cv) => sum + cv, 0) ?? 0;
  const paymentMethodFallback = !hasPreauth && !validationNeeded;

  const displayTotal =
    total + (tip || 0) + (paymentMethodFallback && debit > 0 && !isPaid ? potentialNonCashAdjustments : 0);

  useEffect(() => {
    dispatch(setIsCheckoutComplete(false));
  }, []);

  useEffect(() => {
    if (hasPreauth && (activeCart?.ExistingPayments.length ?? 0) === 0) {
      setPreauthAmt(activeCart?.PreauthInfo.PreauthAmount ?? 0.0);
    }
  }, [hasPreauth, activeCart, setPreauthAmt]);

  useEffect(() => {
    const existingPayment = activeCart?.ExistingPayments?.find((x) => !x.PollingComplete);
    if (existingPayment) {
      setElectronicPaymentType(existingPayment?.PaymentProviderType);
    }
  }, [activeCart, setElectronicPaymentType]);

  useEffect(() => {
    // TODO: 57212 what to do with this loading message? 'Payment in Progress on Terminal Device'
    if (electronicPaymentType) {
      void handlePollPayment();
    }
  }, [electronicPaymentType, handlePollPayment]);

  useEffect(() => {
    if (electronicPaid) {
      if (!selectedRegister?.id || selectedRegister.id === 0) {
        dispatch(errorNotification('No register selected, please select a register'));
        dispatch(setActiveCart(null));
        navigate(-1);
      } else {
        pay();
      }
    }
  }, [electronicPaid, navigate, pay, dispatch, selectedRegister]);

  useEffect(() => {
    // if we ever implement credit (planet payments?) in curbside this will need to account for credit
    if (!!paymentSuccess && !isPaid) {
      setIsPaid(true);
      setDebit(paymentSuccess.DebitPaid);
      dispatch(setActiveCart((cart) => ({ ...cart, TipAmount: paymentSuccess.TipAmount ?? 0.0 })));
      onPayDebitSuccess();
    }
    // We removed onPayDebitSuccess from the deps intentionally becuase adding it causes an infinite render loop
  }, [paymentSuccess]);

  const parseReasonCode = (message: string): string => {
    const splitMessage = message.replaceAll('"', '').trim().split(' ');
    return splitMessage[splitMessage.length - 1];
  };

  if (showCanPayCheckout) {
    return (
      <CanPayCheckout
        amount={canpayPaid}
        onPay={() => {
          setShowCanPayCheckout(false);
          setIsPaid(true);
        }}
      />
    );
  }

  const initiateCheckout = async (
    cashPaid: number,
    debitPaid: number,
    preauthPaid: number,
    total: number,
    giftPaid: number,
  ) => {
    logger.info(`initiate checkout for shipment ${String(shipmentId)}`, {
      cart: activeCart,
      key: customEventKeys.cart.checkout,
      shipmentId,
    });

    try {
      const tip = activeCart?.TipAmount ?? 0;
      preauthPaid = preauthPaid > 0 && hasPreauth ? total + tip : 0;
      dispatch(setIsCheckoutComplete(false)); // assume failure

      const paid = cashPaid + debitPaid + giftPaid + preauthPaid;
      let change = paid - tip - total;
      if (change < 0) {
        change = 0;
      }
      dispatch(setChangeAmountDue(change));
      setPreauthAmt(preauthPaid);
      dispatch(setTotalAmountPaid(paid));

      await openCart();

      await checkout();
    } catch (e) {
      logger.error(e, { message: `checkout error for shipment ${String(shipmentId)}` });
      onFailure();
    }
  };

  const payExact = (type: string) => {
    setCash(type === 'cash' ? total : 0);
    setGiftcardPaid(type === 'giftcard' ? total : 0);
    setDebit(type === 'debit' ? total + potentialNonCashAdjustments : 0);
    setCanpayPaid(type === 'canpay' ? total : 0);
    setPreauthAmt(0);
  };

  const showDebitPayButton = !!integratedDebitProvider && !isPaid && debit > 0;
  const showCanPayPayButton = isAcceptCanPayFFEnabled && !isPaid && canpayPaid > 0 && !showDebitPayButton;
  const showCheckoutButton = !showDebitPayButton && !showCanPayPayButton && !isRethink;

  const canCheckOut = hasPreauth || debit + cash + canpayPaid + giftcardPaid >= total + tip;

  const dutchiePrepaidWithError = paymentMethodFallback ? 0 : preauthAmt;

  let change =
    debit +
    cash +
    canpayPaid +
    giftcardPaid +
    dutchiePrepaidWithError -
    total -
    tip -
    (debit > 0 && !isPaid ? potentialNonCashAdjustments : 0);

  let remaining = total - debit - cash - canpayPaid - giftcardPaid - dutchiePrepaidWithError;

  remaining = remaining < 0 ? 0 : remaining;
  change = change < 0 ? 0 : change;

  const isLoading =
    isPollingPayment ||
    isPrintingReceipt ||
    isPaying ||
    isLoadingCartDetail ||
    isLoadingDeliveryInfo ||
    isCheckingOut ||
    isRegalaPrintingReceipt;

  return (
    <>
      <>
        {isDutchiePay && (
          <DutchiePayMessage data-testid='dutchie-pay-overlay'>
            <DutchiePay />
            <p>
              This order was paid for using DutchiePay. No payment selection is needed. To complete this transaction,
              simply tap the <b>"Checkout"</b> button below.
            </p>
          </DutchiePayMessage>
        )}
        {!isDutchiePay && isDutchiePayError && dutchiePayErrorMessage !== '' && (
          <DutchiePayErrorMessageContainer data-testid='dutchie-pay-error-overlay'>
            <div>
              <ErrorIcon />
            </div>
            <DutchiePayErrorMessage>
              <p>{dutchiePayErrorMessage}</p>
            </DutchiePayErrorMessage>
          </DutchiePayErrorMessageContainer>
        )}
        {isRethink && (
          <RethinkBanner data-testid='rethink-overlay'>
            <div>
              <InfoIcon />
            </div>
            <p>This order has been paid using a card. To complete this order click “Complete Order” below.</p>
          </RethinkBanner>
        )}
        {!isRethink && isRethinkError && rethinkErrorMessage !== '' && (
          <DutchiePayErrorMessageContainer data-testid='rethink-error-overlay'>
            <div>
              <ErrorIcon />
            </div>
            <DutchiePayErrorMessage>
              <p>{rethinkErrorMessage}</p>
            </DutchiePayErrorMessage>
          </DutchiePayErrorMessageContainer>
        )}
        {!validationNeeded && (
          <PaymentMethodContainer>
            {paymentMethodFallback && (
              <>
                {!isCurbsideCashlessFFEnabled && (
                  <InputContainer>
                    <InputLabel>Cash:</InputLabel>
                    <InputRowContainer>
                      <DutchieInput
                        allowNegative={false}
                        automationId='custom-cash-input'
                        decimalScale={2}
                        fixedDecimalScale
                        getInputRef={cashInputRef}
                        inputMode='decimal'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCash(Number(e.target.value))}
                        onFocus={() => {
                          cashInputRef.current?.setSelectionRange(0, 0);
                        }}
                        placeholder='...'
                        startAdornment={<DollarInput>$</DollarInput>}
                        type='number'
                        value={cash}
                      />
                      <ExactButton
                        data-dd-action-name='Exact (cash)'
                        data-testid='exact-cash-button'
                        disabled={isPaid}
                        onClick={() => payExact('cash')}
                      >
                        Exact
                      </ExactButton>
                    </InputRowContainer>
                  </InputContainer>
                )}
                {debitDisabled && <DebitWarning />}
                <InputContainer>
                  <InputLabel>Debit:</InputLabel>
                  <InputRowContainer>
                    <DutchieInput
                      allowNegative={false}
                      automationId='custom-debit-input'
                      decimalScale={2}
                      disabled={isPaid || debitDisabled}
                      fixedDecimalScale
                      getInputRef={debitInputRef}
                      inputMode='decimal'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const debitValue = Number(e.target.value);
                        const shouldApplySurcharge = !!debitValue;

                        setAddedSurcharge(shouldApplySurcharge);
                        setDebit(debitValue);
                      }}
                      onFocus={() => {
                        debitInputRef.current?.setSelectionRange(0, 0);
                      }}
                      placeholder='...'
                      startAdornment={<DollarInput>$</DollarInput>}
                      type='number'
                      value={debit}
                    />
                    <ExactButton
                      data-dd-action-name='Exact (debit)'
                      data-testid='exact-debit-button'
                      disabled={isPaid || debitDisabled}
                      onClick={() => payExact('debit')}
                    >
                      Exact
                    </ExactButton>
                  </InputRowContainer>
                  {potentialNonCashAdjustments > 0 && (
                    <NonCashFeeContainer>
                      {!addedSurcharge
                        ? `$${potentialNonCashAdjustments.toFixed(2)} ${String(paymentFeeName)} fee will be added to
                      order total.`
                        : `$${potentialNonCashAdjustments.toFixed(
                            2,
                          )} ${String(paymentFeeName)} fee has been added to order total.`}
                    </NonCashFeeContainer>
                  )}
                </InputContainer>

                {isAcceptCanPayFFEnabled && (
                  <InputContainer>
                    <InputLabel>CanPay:</InputLabel>
                    <InputRowContainer>
                      <DutchieInput
                        allowNegative={false}
                        automationId='custom-canpay-input'
                        decimalScale={2}
                        disabled={isPaid}
                        fixedDecimalScale
                        getInputRef={canPayInputRef}
                        inputMode='decimal'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCanpayPaid(Number(e.target.value))}
                        onFocus={() => {
                          canPayInputRef.current?.setSelectionRange(0, 0);
                        }}
                        placeholder='...'
                        startAdornment={<DollarInput>$</DollarInput>}
                        type='number'
                        value={canpayPaid}
                      />
                      <ExactButton
                        data-dd-action-name='Exact (canpay)'
                        data-testid='exact-canpay-button'
                        disabled={isPaid}
                        onClick={() => payExact('canpay')}
                      >
                        Exact
                      </ExactButton>
                    </InputRowContainer>
                  </InputContainer>
                )}

                {isCheckOutGiftCardFFEnabled && (
                  <InputContainer>
                    <InputLabel>Gift Card:</InputLabel>
                    <InputRowContainer>
                      <DutchieInput
                        allowNegative={false}
                        automationId='custom-giftcard-input'
                        decimalScale={2}
                        disabled={isPaid}
                        fixedDecimalScale
                        getInputRef={giftCardInputRef}
                        inputMode='decimal'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGiftcardPaid(Number(e.target.value))}
                        onFocus={() => {
                          giftCardInputRef.current?.setSelectionRange(0, 0);
                        }}
                        placeholder='...'
                        startAdornment={<DollarInput>$</DollarInput>}
                        type='number'
                        value={giftcardPaid}
                      />
                      <ExactButton
                        data-dd-action-name='Exact (gift card)'
                        data-testid='exact-giftcard-button'
                        disabled={isPaid}
                        onClick={() => payExact('giftcard')}
                      >
                        Exact
                      </ExactButton>
                    </InputRowContainer>
                  </InputContainer>
                )}
              </>
            )}
          </PaymentMethodContainer>
        )}
      </>
      {totals && (
        <TotalSummaryContainer>
          <OrderDetailsWrapper>
            <StyledLabel>Order details</StyledLabel>
          </OrderDetailsWrapper>
          <CartSummary displayNonCashFee={(paymentMethodFallback && !!debit) || isRethink} totals={totals} />
        </TotalSummaryContainer>
      )}
      <OrderDetailsContainer>
        <CartOrderTotal
          cash={cash}
          change={change}
          debit={debit}
          displayTotal={displayTotal}
          paymentMethodFallback={paymentMethodFallback}
          remaining={remaining}
        />
        {!validationNeeded && (
          <>
            {showCheckoutButton && (
              <ActionButton
                buttonSize='extra-large'
                ddActionName='Pay (standard)'
                disabled={!canCheckOut}
                fullWidth
                label='Pay'
                loading={isLoading}
                onClick={() => initiateCheckout(cash, debit, preauthAmt, total, giftcardPaid)}
              />
            )}
            {isRethink && (
              <ActionButton
                buttonSize='extra-large'
                ddActionName='Pay (Rethink)'
                disabled={!canCheckOut}
                fullWidth
                label='Complete Order'
                loading={isLoading}
                onClick={() => initiateCheckout(cash, debit, preauthAmt, total, giftcardPaid)}
              />
            )}
            {showDebitPayButton && (
              <ActionButton
                buttonSize='extra-large'
                ddActionName='Pay (debit)'
                fullWidth
                label='Pay'
                loading={isLoading}
                onClick={() => setElectronicPaid(debit.toFixed(2))}
              />
            )}
            {showCanPayPayButton && (
              <ActionButton
                buttonSize='extra-large'
                ddActionName='Pay (CanPay)'
                fullWidth
                label='Pay'
                loading={isLoading}
                onClick={() => setShowCanPayCheckout(true)}
              />
            )}
          </>
        )}
      </OrderDetailsContainer>
    </>
  );
};

const DutchiePayMessage = styled.div`
  border: 1px solid #d8dee3;
  font-family: Lato, Matter, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1.875rem 1.25rem;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 200px;
    margin-bottom: 1.25rem;
  }

  & > p {
    text-align: center;
    margin: 0;
  }
`;

const RethinkBanner = styled.div`
  font-family: Lato, Matter, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
  background-color: #edf1fa;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  & > svg {
    width: 200px;
    margin-bottom: 1.25rem;
  }

  & > p {
    text-align: left;
    margin: 0;
  }
`;

const ActionButton = styled(Button)`
  margin: 2rem 0;
`;

export const NonCashAdjustmentFeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: var(--color-blue-10);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 1.5rem;
  margin-top: -1rem;
`;

export const NonCashAdjustmentFeeText = styled.span`
  font-weight: 400;
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: var(--color-blue-80);
  padding-left: 8px;
`;

export const NonCashAdjustmentFeeWeightedText = styled.span`
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: var(--color-blue-80);
`;

export const DutchiePayErrorMessageContainer = styled.div`
  background-color: #fae8ea;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
`;

export const DutchiePayErrorMessage = styled.div`
  line-height: 1.125rem;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #be0517;
  p {
    margin-bottom: 0;
  }
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InputLabel = styled.div`
  color: #2c3236;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
`;

const InputRowContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ExactButton = styled.button<{ disabled?: boolean }>`
  background-color: var(--color-gray-20);
  color: #2e3538;
  border: none;
  padding: 0.75rem 1.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: var(--color-gray-70);
    color: var(--color-gray-50);
    `}
`;

const NonCashFeeContainer = styled.div`
  font-size: 0.8125rem;
  line-height: 0.875rem;
  font-weight: 600;
  color: #646d72;
`;

const DollarInput = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #a0a8ab;
`;

const LabelCss = css`
  color: black;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2rem;
`;

const StyledLabel = styled.div`
  ${LabelCss}
`;

const TotalSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  background-color: white;
  border-top: 1px solid #e3e6e7;
  bottom: 0;
`;

const OrderDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
