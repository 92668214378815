import { type FC } from 'react';

import { styled } from 'styled-components';

import ScanIcon from 'src/assets/scan-icon.svg?react';
import { colors } from 'src/css/theme';

export const ScanPackageTag: FC = () => (
  <AssignActionButton data-testid='preorder_scan-package-button' disabled>
    <ScanIcon />
    Scan package
  </AssignActionButton>
);

const AssignActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
  color: var(--color-blue-60);
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      stroke: var(--color-blue-60);
    }
  }

  &:disabled {
    cursor: default;
  }
`;
