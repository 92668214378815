import { type CartItem, type Preorder } from 'src/models/cart';

/* eslint-disable @typescript-eslint/prefer-enum-initializers */
export enum CartItemType {
  Cart,
  Preorder,
}
/* eslint-enable @typescript-eslint/prefer-enum-initializers */

export type CartDisplayItem =
  | ({ isPreorderItem?: boolean; type: CartItemType.Cart } & CartItem)
  | ({ isPreorderItem?: boolean; type: CartItemType.Preorder } & Preorder);
