import { Button as MuiButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledLinkButton = styled(MuiButton)<{ $variant: string }>`
  text-transform: none !important;
  font: var(--font-small-13pt-semibold);
  ${({ $variant }) => css`
    ${$variant === 'primary' && primary}
    ${$variant === 'secondary' && secondary}
  `}

  &:hover {
    background: none;
  }
  &:focus {
    outline: none;
  }

  :disabled {
    color: var(--color-greyscale-grey-70);
  }
`;

const primary = css`
  color: var(--color-secondary-sapphire);
  background-color: var(--color-primary-white);
`;

const secondary = css`
  color: var(--color-greyscale-black);
  background-color: var(--color-primary-white);
  text-decoration: underline;
`;
