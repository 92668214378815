import React from 'react';
import { styled } from 'styled-components';

import { LoaderRing, InnerRing } from './loader.styles';

export enum LoadingSpinnerSizeProp {
  '1x' = '1em',
  '2x' = '2em',
  '3x' = '3em',
  '4x' = '4em',
  '5x' = '5em',
  '6x' = '6em',
  '7x' = '7em',
  '8x' = '8em',
  '9x' = '9em',
  '10x' = '10em',
  'lg' = '1.33em',
  'sm' = '0.875em',
  'xs' = '0.75em',
}

export type LoaderProps = {
  size?: keyof typeof LoadingSpinnerSizeProp;
  variant?: 'black' | 'blue' | 'default' | 'grey' | 'green' | 'custom';
  color?: string;
};

export function Loader({ size = '1x', variant = 'default', color }: LoaderProps): JSX.Element {
  return (
    <LoaderRing
      aria-label='Loading'
      aria-valuetext='Loading'
      data-testid='loading-spinner_icon'
      size={LoadingSpinnerSizeProp[size]}
    >
      <InnerRing $variant={variant} $color={color} />
      <InnerRing $variant={variant} $color={color} />
      <InnerRing $variant={variant} $color={color} />
      <InnerRing $variant={variant} $color={color} />
    </LoaderRing>
  );
}

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
