/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import { Network } from '@capacitor/network';
import { Http } from '@capacitor-community/http';
import { get } from 'lodash-es';
import LogRocket from 'logrocket';

import { getUrl } from '@dutchie-pos/requests';

export type BaseAPIResponse<T> = {
  Data?: T;
  Message?: string;
  Result: boolean;
};

export type HttpOptions = {
  errorMessage?: string;
  ignoreFormat?: boolean;
  integrationErrorMessage?: boolean;
  showError?: boolean;
  showServerSuccess?: boolean;
  successMessage?: string;
};

export const createAuthBody = (body: object = {}, Guest_id?: number) => {
  const authBody: Record<string, number | string | undefined> = {
    ...body,
    // these override body and are not currently defined
    // SessionId: window.sessionStorage.getItem('SessionGId') ?? '',
    // LspId: window.sessionStorage.getItem('LspId') ?? 0,
    // LocId: window.sessionStorage.getItem('LocId') ?? 0,
    // OrgId: window.sessionStorage.getItem('OrgId') ?? 0,
  };
  if (!Guest_id) {
    authBody.UserId = get(body, 'UserId') ?? window.sessionStorage.getItem('UserId') ?? undefined;
  } else {
    authBody.Guest_id = Guest_id;
  }
  return authBody;
};

const getAppHeaders = () => {
  const result: Record<string, string> = { AppName: 'MobileCheckout' };
  const version = import.meta.env.PACKAGE_VERSION;
  if (version) {
    result.AppVersion = version;
  }

  const logRocketSessionUrl = LogRocket.sessionURL;
  if (logRocketSessionUrl) {
    result['X-LogRocket-URL'] = logRocketSessionUrl;
  }

  return result;
};

export async function doPostWithoutParams<T>(url: string, data?: object): Promise<T> {
  const httpResp = await fetch(getUrl(url), {
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getAppHeaders(),
    },
    method: 'POST',
  });

  return await httpResp.json();
}

export const post = async <T>(
  url: string,
  body?: object,
  options: HttpOptions = { integrationErrorMessage: true },
): Promise<T> =>
  await Http.post({
    data: createAuthBody(body),
    headers: { 'content-type': 'application/json', ...getAppHeaders() },
    url: getUrl(url),
    webFetchExtra: {
      credentials: 'include',
    },
  })
    .then((resp) => {
      const result = resp.data;
      if (options?.ignoreFormat) {
        return result;
      }
      return result.Result
        ? result.Data
        : Promise.reject(
            `${String(result.Message)} ${String(
              result.IntegrationMessage && options?.integrationErrorMessage ? result.IntegrationMessage : '',
            )}`,
          );
    })
    .catch(async (error: unknown) => {
      const connectionStataus = await Network.getStatus();
      if (!connectionStataus?.connected) {
        return await Promise.reject('No internet connection found.');
      }

      if (error instanceof Error && error?.message) {
        const errorObject = JSON.parse(error.message);
        return errorObject.IntegrationMessage
          ? await Promise.reject(`${String(errorObject.Message)} ${String(errorObject.IntegrationMessage)}`)
          : await Promise.reject(errorObject.Message);
      }
      return await Promise.reject(error);
    });

export const getReq = async <T>(url: string, options: HttpOptions = {}): Promise<T> =>
  await Http.get({
    headers: { 'Content-Type': 'application/json', ...getAppHeaders() },
    url: getUrl(url),
    webFetchExtra: {
      credentials: 'include',
    },
  })
    .then((resp) => {
      const result = resp.data;
      if (options?.ignoreFormat) {
        return result;
      }
      return result?.Result ? result?.Data : Promise.reject(result?.Message);
    })
    // eslint-disable-next-line promise/no-return-wrap
    .catch((error: unknown) => Promise.reject(error));
