import React, { forwardRef } from 'react';

import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { Arrow } from 'src/components/arrow';
import { DutchieInput, type DutchieInputProps } from 'src/components/dutchie-input';
import { colors } from 'src/css/theme';

type CalendarInputProps = DutchieInputProps & {
  width: string;
};

const StyledCalendarInput = styled(DutchieInput)<CalendarInputProps>`
  width: ${({ width }) => `${width}`};

  & > img {
    top: 0 !important;
    left: 0 !important;
    width: 26px;
  }
`;

export const CalendarInput = forwardRef((props: CalendarInputProps, ref: React.Ref<HTMLInputElement>) => (
  <StyledCalendarInput {...props} />
));

export const DatePickerHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
`;

export const YearWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  position: relative;
`;

export const DateSpan = styled.span`
  color: ${colors.dutchie.almostBlack};
  font-size: 20px;
  font-weight: bold;
`;

export const YearSelect = styled.select`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: hidden;
  opacity: 0;
`;

export const ArrowWrapperDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const MonthArrow = styled(Arrow)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const DateCell = {
  width: '3rem',
  height: '2rem',
  radius: '0.5rem',
};

export const DateCellContainerBase = css`
  display: flex;
`;

export const DateCellBase = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 1rem;
  width: ${DateCell.width};
  height: ${DateCell.height};
  border-radius: ${DateCell.radius};
`;

export const DatePickerBase = styled.div`
  margin-bottom: 1rem;

  .react-datepicker {
    border-color: var(--color-gray-20);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  }

  .react-datepicker,
  .react-datepicker__header {
    border-radius: 0.5rem;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    padding: 0;
    background-color: var(--color-grayscale-white);
    border-bottom: none;
  }

  .react-datepicker__day-names {
    ${DateCellContainerBase}
    padding: 0 1rem;
  }

  .react-datepicker__day-name {
    ${DateCellBase}
    color: ${colors.dutchie.grey};
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.5%;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 0 1rem 1rem;
  }

  .react-datepicker__week {
    ${DateCellContainerBase}
    padding-top: 0.375rem;
  }

  .react-datepicker__day {
    ${DateCellBase}

    &:hover {
      color: var(--color-grayscale-white);
      background-color: ${rgba('#0b2034', 0.5)};
    }
  }

  .react-datepicker__day--outside-month {
    color: ${colors.dutchie.grey};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--keyboard-selected {
    color: var(--color-grayscale-white);
    background-color: var(--color-opal-90);
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    border-top-left-radius: ${DateCell.radius};
    border-bottom-left-radius: ${DateCell.radius};
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    border-top-right-radius: ${DateCell.radius};
    border-bottom-right-radius: ${DateCell.radius};
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container > div:first-of-type {
    width: 100%;
  }

  .react-datepicker__time-container {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    border-color: var(--color-gray-20);
    display: flex;
    flex-direction: column;

    .react-datepicker-time__header {
      color: ${colors.dutchie.almostBlack};
      line-height: 2rem;
      padding: 1rem;
    }

    .react-datepicker__time {
      border-bottom-right-radius: inherit;

      .react-datepicker__time-box {
        border-bottom-right-radius: inherit;
      }

      .react-datepicker__time-list {
        border-bottom-right-radius: inherit;
        height: auto !important;
        max-height: 250px;

        .react-datepicker__time-list-item {
          height: 20px !important;

          &.react-datepicker__time-list-item--selected {
            background: var(--color-opal-90);

            &:hover {
              background: ${rgba('#0b2034', 0.5)};
            }
          }
        }
      }
    }
  }
`;
