import type { CartItem, Preorder } from 'src/queries/curbside/types';

import { CartItemType } from './types';

import type { CartDisplayItem } from './types';

export const getQty = (item: CartItem) => (item.WgtCnt === 'Qty' ? item.QtySelected : (item?.Grams ?? 0));

export const getReducedPreorders = (preorders: Preorder[]): Preorder[] => {
  const preordersWithQty = preorders.filter((item) => !!item.QtySelected);

  const reducedPreorders = preordersWithQty.reduce((array: Preorder[], preorder: Preorder) => {
    const addedIndex = array.findIndex((item) => item.ProductId === preorder.ProductId);
    if (addedIndex >= 0) {
      const alreadyAdded = array[addedIndex];
      array.splice(addedIndex, 1, {
        ...alreadyAdded,
        QtySelected: alreadyAdded.QtySelected + preorder.QtySelected,
      });
    } else {
      array.push(preorder);
    }
    return array;
  }, []);

  return reducedPreorders;
};

/**
 * Get an updated preorder object with the amount and cost not yet fulfilled
 */
const getPreorderItemToBeFulfilled = (preorder: Preorder, qtyAlreadyInCart: number): Preorder => {
  const updatedPreorder = { ...preorder };
  updatedPreorder.QtySelected = preorder.QtySelected - qtyAlreadyInCart;
  updatedPreorder.TotalCost = (1 - qtyAlreadyInCart / preorder.QtySelected) * updatedPreorder.TotalCost;

  return updatedPreorder;
};

/**
 * Get all preorder items, unfulfilled AND fulfilled
 * Persist original order. Any fulfilled preorder items populate directly under the unfulfilled item,
 * or take its place.
 */
export const getPreorderItems = (preorders: Preorder[], cart: CartItem[]): CartDisplayItem[] => {
  const preorderItems: CartDisplayItem[] = [];

  if (preorders.length) {
    const reducedPreorders = getReducedPreorders(preorders);

    for (const preorder of reducedPreorders) {
      const cartItemForPreorderItem = cart.filter((x: CartItem) => x.ProductId === preorder.ProductId);
      const qtyAlreadyInCart = cartItemForPreorderItem.reduce((prev: number, cur: CartItem) => prev + getQty(cur), 0);

      // Add unfulfilled preorder item first
      if (qtyAlreadyInCart < preorder.QtySelected) {
        const preorderItemToBeFulfilled = getPreorderItemToBeFulfilled(preorder, qtyAlreadyInCart);

        const updatedPreorderItem: CartDisplayItem = {
          ...preorderItemToBeFulfilled,
          type: CartItemType.Preorder,
        };

        preorderItems.push(updatedPreorderItem);
      }

      // Add fulfilled preorder item(s) second
      if (qtyAlreadyInCart) {
        cartItemForPreorderItem.forEach((cartItem: CartItem) => {
          const updatedCartItem: CartDisplayItem = {
            ...cartItem,
            type: CartItemType.Cart,
          };

          preorderItems.push(updatedCartItem);
        });
      }
    }
  }

  return preorderItems;
};

/**
 * Get all cart items that were never part of a preorder
 */
export const getCartItems = (preorders: Preorder[], cart: CartItem[]): CartDisplayItem[] => {
  const cartItems: CartDisplayItem[] = [];

  cart.forEach((cartItem: CartItem) => {
    const isNonPreorderCartItem = !preorders.find((preorderItem: Preorder) => {
      const cartItemMatchesPreorder = preorderItem.ProductId === cartItem.ProductId;
      const preorderHasQty = preorderItem.QtySelected > 0;
      return cartItemMatchesPreorder && preorderHasQty;
    });

    if (isNonPreorderCartItem) {
      const nonPreorderCartItem: CartDisplayItem = {
        ...cartItem,
        type: CartItemType.Cart,
      };

      cartItems.push(nonPreorderCartItem);
    }
  });

  return cartItems;
};
