import { type Dispatch, type SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { type RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

import { type AppReady, MAX_3RD_PARTY_WAIT_TIME } from '..';

type DatadogContextType = {
  datadog?: typeof datadogRum;
};

export const DatadogContext = createContext<DatadogContextType>({});

export function useDatadog() {
  const datadog = useContext(DatadogContext);
  return datadog;
}

export function extractOperationName(content: string | undefined): string {
  if (content) {
    const regex = /query ([^(]+)/gm;
    const matches = regex.exec(content);
    if (matches && matches.length > 1) {
      return matches[1];
    }
  }
  return 'unknown';
}

// intentionally not typing these params because the datadog type supplied is incorrect
export function beforeSend(event, context) {
  // stop reporting resources for logrocket in datadog
  if (context?.xhr?.responseURL?.includes('r.lr-in-prod.com/i')) {
    return false;
  }
  if (context?.response?.url?.includes('api/graphql') && event.context) {
    event.context = {
      ...event.context,
      operationName: extractOperationName(context?.requestInit?.body as string | undefined),
    };
  }

  return true;
}

export async function initDatadog(datadogConfigOverrides: RumInitConfiguration) {
  // eslint-disable-next-line no-return-await
  return await new Promise<void>((resolve) => {
    try {
      if (import.meta.env.PROD && !datadogRum.getInitConfiguration()) {
        datadogRum.init({
          site: 'datadoghq.com',
          version: import.meta.env.PACKAGE_VERSION,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 0,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
          enableExperimentalFeatures: ['feature_flags'],

          beforeSend,

          ...datadogConfigOverrides,
        });

        datadogRum.startSessionReplayRecording();

        resolve();
      } else if (import.meta.env.DEV) {
        resolve();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize Datadog', error);

      // We will still load the page if Datadog fails to initialize
      resolve();
    }
  });
}

export type DatadogProviderProps = {
  children: React.ReactNode;
  config: RumInitConfiguration;
  ready: AppReady;
  setReady: Dispatch<SetStateAction<AppReady>>;
};

// TODO: import existing tests for these providers

export function DatadogProvider({ children, config, setReady, ready }: DatadogProviderProps) {
  const [datadogReady, setDatadogReady] = useState(false);
  const [datadogForceReady, setDatadogForceReady] = useState(false);
  // const { data: user } = useUser();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDatadogForceReady(true);
    }, MAX_3RD_PARTY_WAIT_TIME);

    async function init() {
      if (!datadogReady && config) {
        await initDatadog(config);
        setDatadogReady(true);
      }
    }
    void init();

    return () => clearTimeout(timeout);
  }, [config, datadogReady]);

  useEffect(() => {
    // Check that the datadog ready state is not already set so the screen doesn't blip after the timeout
    if (!ready.datadog && (datadogReady || datadogForceReady)) {
      // eslint-disable-next-line no-console
      // TODO: something like this..
      // Datadog.identify(action.data.user.Id, {
      //   ...action.data.user,
      //   displayName: action.data.user.FullName,
      //   email: action.data.user.UserName,
      // });
      setReady((prev) => ({
        ...prev,
        datadog: true,
      }));
    }
  }, [ready.datadog, datadogReady, datadogForceReady]);

  const value = useMemo(() => ({ datadog: datadogRum }), [datadogReady]);

  if (!datadogReady && !datadogForceReady) {
    return null;
  }

  return <DatadogContext.Provider value={value}>{children}</DatadogContext.Provider>;
}
