import { type FC } from 'react';

import { styled } from 'styled-components';

import { colors } from 'src/css/theme';
import { useLocation } from 'src/queries/curbside/location-details';
import { cartHasRounding } from 'src/utils/helpers/cart/cart-has-rounding';
import { getFeeNameFromProcessor } from 'src/utils/helpers/payments/get-fee-name-from-processor';

type ICartSummaryProps = {
  displayNonCashFee: boolean;
  totals: {
    creditNonCashAdjustmentFees: number;
    discount: number;
    dutchiePayFees: number;
    feesDonations: number;
    nonCashAdjustmentFees: number;
    rounding: number;
    subtotal: number;
    tax: number;
    tip: number;
    total: number;
  };
};

export const CartSummary: FC<ICartSummaryProps> = (props) => {
  const { data: location } = useLocation();
  const isRoundDownToQuarterEnabled = !!location?.Features.RoundDownToQuarter;
  const isRoundDownToDollarEnabled = !!location?.Features.RoundDownToDollar;
  const isRoundPercentDiscountsEnabled = !!location?.Features.RoundPercentDiscounts;
  const isRoundToNearestNickelEnabled = !!location?.Features.RoundToNearestNickel;
  const isRoundToNearestDollarEnabled = !!location?.Features.RoundToNearestDollar;
  const isRoundDownToNearestFiverEnabled = !!location?.Features.RoundDownToNearestFiver;
  const isCurbsideRoundDownToNearestFiverEnabled = !!location?.Features.CurbsideRoundDownToNearestFiver;
  const isUseFeesDonationsEnabled = !!location?.Features.UseFeesDonations;
  const debitProcessor = location?.Integrations?.DebitProcessor;

  const hasRounding = cartHasRounding({
    RoundDownToQuarter: isRoundDownToQuarterEnabled,
    RoundDownToDollar: isRoundDownToDollarEnabled,
    RoundPercentDiscounts: isRoundPercentDiscountsEnabled,
    RoundToNearestNickel: isRoundToNearestNickelEnabled,
    RoundToNearestDollar: isRoundToNearestDollarEnabled,
    RoundDownToNearestFiver: isRoundDownToNearestFiverEnabled,
    CurbsideRoundDownToNearestFiver: isCurbsideRoundDownToNearestFiverEnabled,
  });

  const paymentFeeName = getFeeNameFromProcessor(debitProcessor);

  return (
    <Container>
      <Row>
        <Label>Subtotal</Label>
        <Value data-testid='cart-summary-subtotal'>${props.totals.subtotal.toFixed(2)}</Value>
      </Row>
      <Row>
        <Label>Discounts</Label>
        <Value data-testid='cart-summary-discounts'>${props.totals.discount.toFixed(2)}</Value>
      </Row>
      <Row>
        <Label>Tax</Label>
        <Value data-testid='cart-summary-tax'>${props.totals.tax.toFixed(2)}</Value>
      </Row>
      {isUseFeesDonationsEnabled && (
        <Row>
          <Label>Fees & Donations</Label>
          <Value data-testid='cart-summary-fees-donations'>${props.totals.feesDonations.toFixed(2)}</Value>
        </Row>
      )}
      {props.totals.dutchiePayFees > 0 && (
        <Row>
          <Label>Dutchie Pay Fees</Label>
          <Value data-testid='cart-summary-dutchie-pay-fees'>${props.totals.dutchiePayFees.toFixed(2)}</Value>
        </Row>
      )}
      {props.displayNonCashFee && props.totals.nonCashAdjustmentFees > 0 && (
        <Row>
          <Label>{paymentFeeName} Fees</Label>
          <Value data-testid='cart-summary-non-cash-adjustment'>${props.totals.nonCashAdjustmentFees.toFixed(2)}</Value>
        </Row>
      )}
      {props.displayNonCashFee && props.totals.creditNonCashAdjustmentFees > 0 && (
        <Row>
          <Label>{paymentFeeName} Fees</Label>
          <Value data-testid='cart-summary-credit-non-cash-adjustment'>
            ${props.totals.creditNonCashAdjustmentFees.toFixed(2)}
          </Value>
        </Row>
      )}
      {props.totals.tip !== null && (
        <Row>
          <Label>Tip</Label>
          <Value data-testid='cart-summary-tip'>${props.totals.tip.toFixed(2)}</Value>
        </Row>
      )}
      {hasRounding && (
        <Row>
          <Label>
            Rounding
            {props.totals.rounding > 0 ? 'Up' : 'Down'}
          </Label>
          <Value data-testid='cart-summary-rounding'>
            {props.totals.rounding > 0 ? '' : '-'} ${Math.abs(props.totals.rounding).toFixed(2)}
          </Value>
        </Row>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  color: var(--color-gray-90);
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Value = styled(Label)`
  text-align: right;
`;
