import React from 'react';

import i18next from 'i18next';

import { omit } from '@dutchie-pos/utils';

import { Loader } from '../loader';
import { Tooltip } from '../tooltip';

// import { useDarkMode } from "src/app/state/dark-mode";
import { StyledButton, ToolTipContainer } from './button.styles';
import { LinkButton } from './link-button';

import type { LoadingSpinnerSizeProp } from '../loader/loader';
import type { SxProps } from '@mui/material';

export type ButtonProps = {
  automationId?: string;
  buttonSize?: 'extra-large' | 'extra-small' | 'large' | 'medium' | 'small';
  className?: string;
  ddActionName?: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fontSize?: string;
  fullWidth?: boolean;
  id?: string;
  label?: React.ReactNode | string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  spinnerSize?: keyof typeof LoadingSpinnerSizeProp;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
  tooltipText?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?:
    | 'danger'
    | 'darkBlue'
    | 'lightBlue'
    | 'link'
    | 'link-secondary'
    | 'outlineBlue'
    | 'primary'
    | 'secondary'
    | 'white'
    | 'yellow';
  visible?: boolean;
};

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): React.JSX.Element => {
  const {
    automationId = '',
    buttonSize = 'medium',
    className,
    ddActionName,
    disabled,
    endIcon,
    fontSize = '1rem',
    fullWidth = false,
    id = '',
    label,
    loading = false,
    onClick,
    spinnerSize = '1x',
    startIcon,
    sx,
    tooltipPlacement = 'top',
    tooltipText,
    type = 'button',
    variant = 'primary',
  } = props;
  // const darkMode = useDarkMode();
  // eslint-disable-next-line import-x/no-named-as-default-member
  const validLabel = typeof label === 'string' ? (i18next.t(label) ?? label) : label;

  const getValidLoader = () => {
    // the loader must be the white variant when a primary or danger button is disabled in order to be visible
    if (disabled && (variant === 'primary' || variant === 'danger')) {
      return <Loader size={spinnerSize} variant='default' />;
    }

    return <Loader size={spinnerSize} variant='grey' />;
  };

  const validLoader = getValidLoader();

  const buttonLabel = loading ? validLoader : validLabel;

  if (variant === 'link') {
    return (
      <LinkButton
        buttonSize={buttonSize}
        data-dd-action-name={ddActionName}
        data-testid={automationId}
        disabled={disabled}
        endIcon={endIcon}
        fontSize={fontSize}
        id={id}
        label={label}
        onClick={onClick}
        startIcon={startIcon}
        sx={sx}
        type={type}
      />
    );
  }

  return tooltipText ? (
    <Tooltip placement={tooltipPlacement} title={tooltipText}>
      <ToolTipContainer>
        <StyledButton
          $buttonSize={buttonSize}
          // $darkMode={darkMode}
          $darkMode={false}
          $fontSize={fontSize}
          $fullWidth={fullWidth}
          $variant={variant}
          className={className}
          data-testid={automationId}
          disabled={disabled}
          endIcon={endIcon}
          id={id}
          onClick={onClick}
          ref={ref}
          startIcon={startIcon}
          sx={sx}
          type={type}
          variant='contained'
        >
          {buttonLabel}
        </StyledButton>
      </ToolTipContainer>
    </Tooltip>
  ) : (
    <StyledButton
      {...omit(props, ['automationId', 'loading', 'buttonSize'])}
      $buttonSize={buttonSize}
      // $darkMode={$darkMode}
      $darkMode={false}
      $fontSize={fontSize}
      $fullWidth={fullWidth}
      $variant={variant}
      className={className}
      data-testid={automationId}
      disabled={disabled}
      endIcon={endIcon}
      id={id}
      onClick={onClick}
      ref={ref}
      startIcon={startIcon}
      sx={sx}
      type={type}
      variant='contained'
    >
      {buttonLabel}
    </StyledButton>
  );
});
