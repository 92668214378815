import { styled } from 'styled-components';

import { CartList } from './cart-list';

export const DeliveryRoute = () => (
  <Container>
    <CartList />
  </Container>
);

const Container = styled.div``;
