import { useCallback } from 'react';

import { round } from 'lodash-es';
import { useParams } from 'react-router';

import { type CartParams } from 'src/app/types/params';
import { useAddItemToShoppingCart } from 'src/queries/cart/add-item-to-shopping-cart';
import type { AddItemToShoppingCartPayload } from 'src/queries/cart/add-item-to-shopping-cart';
import { useCart } from 'src/queries/curbside/get-cart';
import { useLocation } from 'src/queries/curbside/location-details';

import { useAppSelector } from '.';

export type UseAddQtyItemToCart = {
  availableOz?: number;
  batchId?: number;
  cannabisInventory?: string;
  count: number;
  defaultLabelId?: number;
  defaultUnitId?: number;
  inventoryId?: number;
  packageItemId?: number | null;
  productDescription: string;
  productId: number;
  recUnitPrice: number;
  serialNo: string;
  unitPrice: number;
};

export const useAddQtyItemToCart = () => {
  // Global state
  const { shipmentId = '0', customerId = '0' } = useParams<CartParams>();
  const selectedRegisterId = useAppSelector((state) => state.user.selectedRegister?.id);

  // Hooks
  const { mutateAsync: addItemToCart } = useAddItemToShoppingCart();
  const { data: cart } = useCart();
  const { data: location } = useLocation();

  // Computed
  const isLoyaltyAsDiscountEnabled = !!location?.Features.LoyaltyAsDiscount;
  const isRunAutoDiscountEnabled = !!location?.Features.RunAutoDiscount;
  const isRunAutoPriceEnabled = !!location?.Features.RunAutoPrice;
  const isUsingDaysSupply = !!location?.Features.ShowDaysSupplyCalculator;
  const availOzComputed = round(
    (cart?.Allotment.TotalLimit ?? 0) - (cart?.Allotment.TotalInCart ?? 0) - (cart?.Allotment.TotalUsed ?? 0),
    2,
  );

  return useCallback(
    async ({
      availableOz,
      batchId,
      cannabisInventory,
      count,
      defaultLabelId,
      defaultUnitId,
      inventoryId,
      packageItemId,
      productDescription,
      productId,
      recUnitPrice,
      serialNo,
      unitPrice,
    }: UseAddQtyItemToCart) => {
      if (!selectedRegisterId) {
        return;
      }

      const payload: AddItemToShoppingCartPayload = {
        availableOz: availableOz ?? availOzComputed,
        batchId,
        cannabisProduct: cannabisInventory,
        count,
        defaultLabelId,
        defaultUnitId,
        grouping: 'No',
        guestId: Number(customerId),
        inventoryId,
        isLoyaltyAsDiscountEnabled,
        isQuantityItem: true,
        isRunAutoDiscountEnabled,
        isRunAutoPriceEnabled,
        isUsingDaysSupply,
        packageItemId,
        productDescription,
        productId,
        recUnitPrice,
        registerId: selectedRegisterId,
        serialNo,
        shipmentId: Number(shipmentId),
        unitPrice,
        weight: 0,
      };

      // eslint-disable-next-line no-useless-catch
      try {
        await addItemToCart(payload);
      } catch (error) {
        // Need to throw the error to prevent the success notification from displaying
        throw error;
      }
    },
    [
      addItemToCart,
      availOzComputed,
      customerId,
      isLoyaltyAsDiscountEnabled,
      isRunAutoDiscountEnabled,
      isRunAutoPriceEnabled,
      isUsingDaysSupply,
      selectedRegisterId,
      shipmentId,
    ],
  );
};
