import { createContext, useState } from 'react';

import { DocumentViewer } from 'src/app/components/document-viewer/document-viewer';
import {
  defaultDocumentViewerState,
  type DocumentViewerState,
} from 'src/app/components/document-viewer/document-viewer-state';

export type DocumentViewerContextValue = [DocumentViewerState, (state: DocumentViewerState) => void];
export const DocumentViewerContext = createContext<DocumentViewerContextValue | null>(null);

type ProvideDocumentViewerProps = {
  children: React.ReactNode;
};

export const ProvideDocumentViewer = ({ children }: ProvideDocumentViewerProps) => {
  const getAndSetDocumentViewerState = useState(defaultDocumentViewerState);
  return (
    <DocumentViewerContext.Provider value={getAndSetDocumentViewerState}>
      <DocumentViewer />
      {children}
    </DocumentViewerContext.Provider>
  );
};
