/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { type FC, type SVGProps } from 'react';

import { styled } from 'styled-components';

import ChevronLeft from 'src/assets/chevron-left.svg?react';
import { colors } from 'src/css/theme';

export type ButtonProps = {
  automationId?: string;
  children?: React.ReactNode;
  className?: string;
  cta?: boolean;
  ddActionName?: string;
  disabled?: boolean;
  dutchiePayThemed?: boolean;
  fontSize?: string;
  fullWidth?: boolean;
  height?: string;
  id?: string;
  isAdornment?: boolean;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  onClick?: (p?) => void;
  padding?: string;
  secondary?: boolean;
  tertiary?: boolean;
  type?: 'button' | 'reset' | 'submit';
  width?: string;
};

export const DutchieButton = styled(
  ({
    automationId,
    cta,
    ddActionName,
    dutchiePayThemed,
    fontSize,
    fullWidth,
    height,
    isAdornment,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    secondary,
    tertiary,
    width,
    ...rest
  }: ButtonProps) => <button {...rest} data-dd-action-name={ddActionName} data-testid={automationId} />,
)<ButtonProps>`
  background-color: var(--color-blue-60);
  border: solid 1px var(--color-blue-60);
  color: var(--color-grayscale-white);
  cursor: pointer;
  border-radius: 6px;
  padding: ${({ padding }) => padding ?? '0 20px'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  height: ${({ height }) => height ?? '44px'};

  ${({ width }) => width && `width: ${width};`}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

    &:disabled {
    pointer-events: none;
    color: var(--color-grayscale-white);
    background: var(--color-gray-50);
    border-color: var(--color-gray-50);
  }

  ${({ secondary }) =>
    secondary &&
    `
        color: ${colors.dutchie.darkGrey};
        background-color: var(--color-grayscale-white);
        border: solid 1px var(--color-gray-60);
        &:hover {
            color: ${colors.dutchie.almostBlack};
            border-color: ${colors.dutchie.almostBlack};
        }
        &:disabled {
            color: var(--color-gray-60);
            background: var(--color-grayscale-white);
            border-color: var(--color-gray-60);
        }
    `}

  ${({ tertiary }) =>
    tertiary &&
    `
        color: ${colors.dutchie.gunmetal};
        background-color: var(--color-gray-20);
        border: solid 1px var(--color-gray-20);
        &:disabled {
            color: var(--color-gray-70);
            background: var(--color-gray-20);
            border-color: var(--color-gray-20);
        }
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    `
        background-color: var(--color-green-60);
        border: solid 1px var(--color-green-60);
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    `
        background-color: var(--color-green-60);
        border: solid 1px var(--color-green-60);
    `}

    ${({ cta, height }) =>
    cta &&
    `
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: ${height ?? '3.5rem'};
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 1rem 1.5rem;
        letter-spacing: 0.5%;
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    `
        margin-bottom: ${String(marginBottom)}px;
    `}
    ${({ marginTop }) =>
    marginTop &&
    `
        margin-top: ${String(marginTop)}px;
    `}
    ${({ marginLeft }) =>
    marginLeft &&
    `
        margin-left: ${String(marginLeft)}px;
    `}
    ${({ marginRight }) =>
    marginRight &&
    `
        margin-right: ${String(marginRight)}px;
    `}

    ${({ isAdornment }) =>
    isAdornment &&
    `
        &&& {
            font-weight: 500;
            font-size: inherit;
            line-height: inherit;
            height: unset;
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            color: var(--color-blue-60);

            &:disabled {
                color: var(--color-gray-60);
            }
        }
    `}
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;

export const DutchieCancelButton = styled(DutchieButton)`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: var(--color-grayscale-white);
  border: none;
`;

type ButtonWithIconBaseProps = {
  height?: number;
  label?: string;
  large?: boolean;
  side?: 'left' | 'right';
  width?: number;
};

const DutchieButtonWithIconBase = styled(DutchieButton)<ButtonProps & ButtonWithIconBaseProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ large }) => (large ? '44px' : '32px')};
  min-width: 32px;
  ${({ side }) => side === 'right' && 'padding-left: 12px;'}

  & > svg {
    margin-right: ${({ label }) => (label ? 7 : 3)}px;
    margin-left: ${({ label }) => (label ? 7 : 3)}px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

type ButtonWithIconProps = {
  className?: string;
  icon: React.FC<SVGProps<SVGSVGElement>>;
} & ButtonWithIconBaseProps &
  Omit<ButtonProps, 'height' | 'width'>;

export const DutchieButtonWithIcon: FC<ButtonWithIconProps & React.ButtonHTMLAttributes<HTMLElement>> = (props) => {
  const {
    className,
    ddActionName,
    height,
    icon: Icon,
    label,
    large,
    secondary,
    side = 'left',
    title,
    width,
    ...otherProps
  } = props;

  const tag = `${label ?? ''}${label && title ? '-' : ''}${title ?? ''}`;
  const automationId = `button_button-with-icon-base${tag ? `_${tag}` : ''}`;

  return (
    <DutchieButtonWithIconBase
      automationId={automationId}
      className={className}
      ddActionName={ddActionName}
      label={label}
      large={large}
      onClick={props.onClick}
      secondary={secondary}
      side={side}
      {...otherProps}
    >
      {side === 'left' && <Icon height={height ?? 26} width={width ?? 34} />}
      {label}
      {side === 'right' && <Icon />}
    </DutchieButtonWithIconBase>
  );
};

const BackButton = styled(DutchieButtonWithIcon)`
  border: 1px solid var(--color-gray-60);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  & svg {
    height: 20px;
  }
`;

export const DutchieBackButton: FC<React.ButtonHTMLAttributes<HTMLElement>> = (props) => (
  <BackButton
    automationId='back-button'
    ddActionName='back button'
    icon={ChevronLeft}
    onClick={props.onClick}
    secondary
  />
);
