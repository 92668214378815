import { StrictMode } from 'react';

import * as ReactDOM from 'react-dom/client';

import { Root } from './root';

// @ts-expect-error that element will always be there...
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Root />
  </StrictMode>,
);
