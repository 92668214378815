import { createStyledBreakpointsTheme } from 'styled-breakpoints';

export const defaultBreakpoints = createStyledBreakpointsTheme({
  breakpoints: {
    largeTablet: '1024px',
    mediumDesktop: '1440px',
    mobile: '576px',
    smallDesktop: '1350px',
    smallTablet: '768px',
  },
} as const);
