import { type FC } from 'react';

import styled, { keyframes } from 'styled-components';

export const LoadingScreen: FC = () => (
  <Wrapper>
    <Spinner>
      <Bouncer />
      <Bouncer2 />
    </Spinner>
  </Wrapper>
);

const bounce = keyframes`
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-index-loading);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  width: 5rem;
  height: 5rem;
  position: relative;
  z-index: var(--z-index-layer);
`;

const Bouncer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-blue-60);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;

  animation: ${bounce} 2s infinite ease-in-out;
`;

const Bouncer2 = styled(Bouncer)`
  animation-delay: -1s;
`;
