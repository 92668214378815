import styled, { css } from 'styled-components';

import { colors } from 'src/css/theme';

export type ButtonProps = {
  automationId?: string;
  cta?: boolean;
  ddActionName?: string;
  dutchiePayThemed?: boolean;
  fontSize?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  height?: string;
  isAdornment?: boolean;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  onClick?: () => void;
  padding?: string;
  secondary?: boolean;
  tertiary?: boolean;
  transparentBlue?: boolean;
  transparentRed?: boolean;
  width?: string;
};

export const Button = styled(
  ({
    automationId,
    cta,
    ddActionName,
    dutchiePayThemed,
    fontSize,
    fullWidth,
    height,
    isAdornment,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    secondary,
    tertiary,
    transparentBlue,
    transparentRed,
    width,
    ...rest
  }: ButtonProps) => <button {...rest} data-dd-action-name={ddActionName} data-testid={automationId} />,
)<ButtonProps>`
  background-color: var(--color-blue-60);
  border: solid 1px var(--color-blue-60);
  color: var(--color-grayscale-white);
  cursor: pointer;
  border-radius: 6px;
  padding: ${({ padding }) => padding || '0 20px'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  height: ${({ height }) => height || '44px'};

  ${({ width }) => width && `width: ${width};`}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  &:disabled {
    pointer-events: none;
    color: var(--color-grayscale-white);
    background: var(--color-gray-50);
    border-color: var(--color-gray-50);
  }

  &:hover {
    background-color: var(--color-blue-70);
  }

  ${({ secondary }) =>
    secondary &&
    css`
      color: ${colors.dutchie.darkGrey};
      background-color: var(--color-grayscale-white);
      border: solid 1px var(--color-gray-60);
      &:hover {
        color: var(--color-gray-100);
        border-color: var(--color-gray-70);
        background-color: var(--color-gray-20);
      }
      &:disabled {
        color: var(--color-gray-60);
        background: var(--color-grayscale-white);
        border-color: var(--color-gray-60);
      }
    `}

  ${({ tertiary }) =>
    tertiary &&
    css`
      color: ${colors.dutchie.gunmetal};
      background-color: var(--color-gray-20);
      border: solid 1px var(--color-gray-20);
      &:disabled {
        color: var(--color-gray-70);
        background: var(--color-gray-20);
        border-color: var(--color-gray-20);
      }
      &:hover {
        color: var(--color-grayscale-black);
        background-color: var(--color-gray-40);
        border-color: var(--color-gray-40);
      }
    `}

  ${({ transparentBlue }) =>
    transparentBlue &&
    css`
      color: var(--color-blue-60);
      background-color: transparent;
      border: solid 1px var(--color-blue-60);
      &:disabled {
        color: var(--color-gray-60);
        background-color: transparent;
        border-color: var(--color-gray-60);
      }
      &:hover {
        color: var(--color-blue-70);
        background-color: transparent;
        border-color: var(--color-blue-70);
      }
    `}

  ${({ transparentRed }) =>
    transparentRed &&
    css`
      color: var(--color-red-50);
      background-color: transparent;
      border: solid 1px var(--color-red-50);
      &:hover {
        color: var(--color-red-70);
        background-color: transparent;
        border-color: var(--color-red-70);
      }
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    css`
      background-color: var(--color-green-60);
      border: solid 1px var(--color-green-60);

      &:hover {
        background-color: var(--color-green-70);
        border-color: var(--color-green-70);
      }
    `}

    ${({ cta, height }) =>
    cta &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: ${height ?? '3.5rem'};
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 1rem 1.5rem;
      letter-spacing: 0.5%;
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
    ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}px;
    `}
    ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}
    ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}

    ${({ isAdornment }) =>
    isAdornment &&
    css`
      &&& {
        font-weight: 500;
        font-size: inherit;
        line-height: inherit;
        height: unset;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        color: var(--color-blue-60);

        &:disabled {
          color: var(--color-gray-60);
        }
      }
    `}
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;
