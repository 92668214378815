import { type FC } from 'react';

import { styled } from 'styled-components';

import { DutchieButton } from 'src/components/dutchie-button';
import { colors } from 'src/css/theme';

import { ContentTitle } from './content-title';

type SelectionListProps = {
  hideBackButton?: boolean;
  list: any[];
  name: string;
  noOptionsText?: string;
  onGoBack?: () => void;
  onSelection: (location) => void;
  value: string;
};

export const SelectionList: FC<SelectionListProps> = (props) => (
  <div>
    <ContentTitle data-testid='select-list-header-name' noButton={props.hideBackButton} onGoBack={props.onGoBack}>
      Select {props.name}
    </ContentTitle>
    {props.list.length ? (
      props.list.map((x, index) => (
        <Row data-testid='selection-list-button' key={x[props.value] + index}>
          <DutchieButton fullWidth secondary onClick={() => props.onSelection(x)}>
            {x[props.value]}
          </DutchieButton>
        </Row>
      ))
    ) : (
      <Message>{props.noOptionsText ?? ''}</Message>
    )}
  </div>
);

const Row = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: var(--color-gray-60);
  font-size: 1rem;
  line-height: 1.5rem;
`;
