import { type FC, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { styled } from 'styled-components';

import { BottomDrawer } from 'src/components/bottom-drawer';
import { Button } from 'src/components/button';
import { colors } from 'src/css/theme';
import { GetRoutesKey } from 'src/queries/curbside/get-my-routes';
import { useLocation } from 'src/queries/curbside/location-details';
import { useReadyForDeliveryMutation } from 'src/queries/curbside/ready-for-delivery';
import { useRestockMutation } from 'src/queries/curbside/restock';
import { useRouteReadyForDeliveryMutation } from 'src/queries/curbside/route-ready';
import type { DeliveryRouteType } from 'src/queries/curbside/types';
import { useUser } from 'src/queries/curbside/user-details';
import { errorNotification, successNotification } from 'src/store/actions/notifications-actions';
import { type BaseAPIResponse } from 'src/utils/helpers/http';
import { useAppDispatch, useAppSelector } from 'src/utils/hooks/index';
import { customEventKeys, logger } from 'src/utils/logger';

import { ScanBarcodeDrawer } from './checkout/cart-items/components/scan-barcode-drawer';
import { ContentTitle } from './content-title';
import { DisplayDateRange } from './date';

type SelectRouteProps = {
  list: DeliveryRouteType[];
  onSelection: (route: DeliveryRouteType) => void;
};

type ScanResponse = {
  NeedsReadyForDelivery: boolean;
  PackagesStocked: number;
  RouteAssigned?: number;
  ShipmentsToReady?: number[];
};

export const SelectRoute: FC<SelectRouteProps> = (props) => {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister?.id);
  const userName = user?.FullName;
  const dispatch = useAppDispatch();
  const [isScanBarcodeDrawerOpen, setIsScanBarcodeDrawerOpen] = useState(false);
  const { data: location } = useLocation();
  const isAddItemsFFEnabled = !!location?.Features.AddItemsInMobileCheckout;
  const isRemoveItemsFFEnabled = !!location?.Features.RemoveItemsInMobileCheckout;
  const readyForDelivery = useReadyForDeliveryMutation({
    onError: () => dispatch(errorNotification('Failed to mark delivery ready')),
    onSuccess: () => {
      // do nothing
    },
  });
  const routeReady = useRouteReadyForDeliveryMutation({
    onError: (err) => dispatch(errorNotification(`Failed to mark delivery ready ${String(err)}`)),
    onSuccess: () => void dispatch(successNotification('Route ready')),
  });
  const doRestock = useRestockMutation({
    onError: (e) => dispatch(errorNotification(`Failed to restock: ${String(e)}`)),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess: async (resp: BaseAPIResponse<ScanResponse>) => {
      if (!resp?.Data) {
        void dispatch(errorNotification('No items or route found'));
        return;
      }

      if (resp.Data.NeedsReadyForDelivery && resp.Data.ShipmentsToReady && resp.Data.RouteAssigned) {
        const requests: Promise<any>[] = [];
        for (const shipmentId of resp.Data.ShipmentsToReady) {
          requests.push(readyForDelivery.mutateAsync({ ShipmentID: shipmentId }));
        }
        await Promise.all(requests);
        await routeReady.mutateAsync({ DeliveryRouteId: resp.Data.RouteAssigned });
        void queryClient.invalidateQueries({ queryKey: [GetRoutesKey, selectedRegister ?? 0] });
      }

      if (resp.Data.PackagesStocked > 0) {
        void dispatch(successNotification(`Restocked ${String(resp.Data.PackagesStocked)} items`));
      }

      if (resp.Data.RouteAssigned) {
        void dispatch(successNotification(`Route assigned`));
      }

      if (!resp.Data.RouteAssigned && resp.Data.PackagesStocked === 0) {
        void dispatch(errorNotification('No items or route found'));
      }
    },
  });

  const onScan = async (scannedString: string) => {
    logger.debug(`kit QR code scanned: ${scannedString}`, {
      key: customEventKeys.scanning.scanReceived,
      scannedString,
      type: 'kit',
    });

    const data = JSON.parse(scannedString);
    doRestock.mutate({
      RegisterId: selectedRegister ?? 0,
      RoomId: data.RoomId,
      SubRoomId: data.SubRoomId,
    });
    setIsScanBarcodeDrawerOpen(false);
  };

  return (
    <>
      <Container>
        <ContentContainer>
          <ContentTitle onGoBack={() => navigate('/registers')}>Select Route</ContentTitle>
          <WelcomeText>Welcome,{userName}!</WelcomeText>
          <RoutesListContainer>
            {(!props.list || props.list.length === 0) && <NoRoutesMessage>No Routes Assigned</NoRoutesMessage>}
            {props.list.map((x) => (
              <Route
                data-testid={`select-route-button-${String(x.DeliveryRouteId)}`}
                key={x.DeliveryRouteId}
                onClick={() => props.onSelection(x)}
              >
                <Bold>Time Window:</Bold>
                <TimeText from={x.StartDate} to={x.EndDate} />
                <Bold>
                  # Of Orders:
                  {x.NumberOfOrders}
                </Bold>
              </Route>
            ))}
          </RoutesListContainer>
        </ContentContainer>
        {isAddItemsFFEnabled && isRemoveItemsFFEnabled && (
          <StickyFooter>
            <ButtonContainer>
              <StyledButton onClick={() => setIsScanBarcodeDrawerOpen(true)} transparentBlue>
                Scan Kit
              </StyledButton>
            </ButtonContainer>
          </StickyFooter>
        )}
        <BottomDrawer
          height={550}
          marginX={0}
          marginY={0}
          onClose={() => setIsScanBarcodeDrawerOpen(false)}
          open={isScanBarcodeDrawerOpen}
        >
          {isScanBarcodeDrawerOpen && (
            <ScanBarcodeDrawer onClose={() => setIsScanBarcodeDrawerOpen(false)} onScan={onScan} qrCode />
          )}
        </BottomDrawer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  padding: 0 24px;
`;

const RoutesListContainer = styled.div`
  flex: 1;
`;

const WelcomeText = styled.div`
  color: var(--color-gray-90);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const NoRoutesMessage = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Route = styled.div`
  width: 100%;
  border: 1px solid var(--color-gray-60);
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 170%;
`;

const Bold = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
`;

const TimeText = styled(DisplayDateRange)`
  color: var(--color-gray-90);
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 452px;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 163px;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px -2px 4px 0px #00000014;
  padding: 20px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
